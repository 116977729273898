<template>
  <l-default>
    <OTitle>Affiliate</OTitle>
    <CShareThisInline />
    <div>
      <h2 class="c_affiliate">AFFILIATE PROGRAM</h2>
      <p>{{ this.description }}</p>
      <p><strong>Conditions:</strong></p>
      <ul>
        <li v-for="(message, index) in conditions" v-bind:key="index">{{ message }}</li>
      </ul>
      <p><strong>Stats:</strong></p>
      <ul>
        <li>Referrals: {{ this.count }}</li>
        <li>Earned credits: {{ this.credits }}</li>
      </ul>
      <p>
        Earned credits are automatically added to your balance. Here we list the total amount of credits ever earned,
        just for information.
      </p>
      <p>LINK: <em>{{ this.url }}</em></p>

      <div class="btn-group btn-group--horizontal">
        <OButton v-on:click="copyLink" theme="primary" type="button">{{ copyButtonLabel }}</OButton>
        <OButton v-on:click="share" theme="primary" type="button">Share</OButton>
      </div>

    </div>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import OButton from '@/objects/OButton'
import CShareThisInline from '@/components/CShareThisInline.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PagePricing',
  components: { CShareThisInline, OTitle, LDefault, OButton },
  data: () => ({
    loading: true,
    code: '',
    message: '',
    description: '',
    url: '',
    count: 0,
    conditions: [],
    copyButtonLabel: 'COPY',
    credits: 0
  }),
  methods: {
    async load () {
      const api = await getApi()
      const result = await api.getShare({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      })
      if (result.error) {
        this.error = result.message
        return false
      }

      this.code = result.code
      this.message = result.message
      this.description = result.description
      this.url = result.url
      this.count = result.count
      this.conditions = result.conditions
      this.credits = result.credits
    },
    share () {
      if (navigator.share) {
        navigator.share({
          title: 'MyPhoneRobot',
          text: this.message,
          url: this.url
        })
      }
    },
    copyLink () {
      navigator.clipboard.writeText(this.url)
      this.copyButtonLabel = 'COPIED'
    }
  },
  mounted () {
    this.load()
    while (document.getElementsByClassName('st-sticky-share-buttons').length) {
      document.getElementsByClassName('st-sticky-share-buttons')[0].remove()
    }
  }
}
</script>

<style lang="scss">

.c_affiliate {
  color: $primary;
}

.btn-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 1rem;
  margin-bottom: $vertical-margin;

  & > * {
    flex: 1 1 auto;
  }

  &--horizontal .o-btn + .o-btn {
    margin-left: $horizontal-margin;
  }
}

</style>
